exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actions-js": () => import("./../../../src/pages/actions.js" /* webpackChunkName: "component---src-pages-actions-js" */),
  "component---src-pages-bundle-js": () => import("./../../../src/pages/bundle.js" /* webpackChunkName: "component---src-pages-bundle-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-flow-gallery-index-js": () => import("./../../../src/pages/flow/gallery/index.js" /* webpackChunkName: "component---src-pages-flow-gallery-index-js" */),
  "component---src-pages-flow-index-js": () => import("./../../../src/pages/flow/index.js" /* webpackChunkName: "component---src-pages-flow-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-landing-js": () => import("./../../../src/pages/lab/landing.js" /* webpackChunkName: "component---src-pages-lab-landing-js" */),
  "component---src-pages-overlap-js": () => import("./../../../src/pages/overlap.js" /* webpackChunkName: "component---src-pages-overlap-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-md": () => import("./../../../src/pages/privacy.md" /* webpackChunkName: "component---src-pages-privacy-md" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-timepage-js": () => import("./../../../src/pages/timepage.js" /* webpackChunkName: "component---src-pages-timepage-js" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-changing-action-behavior-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/configuring-actions/changing-action-behavior.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-changing-action-behavior-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-changing-sound-effects-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/configuring-actions/changing-sound-effects.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-changing-sound-effects-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-customizing-the-look-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/configuring-actions/customizing-the-look.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-customizing-the-look-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-editing-gestures-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/configuring-actions/editing-gestures.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-configuring-actions-editing-gestures-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-action-card-behavior-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/action-card-behavior.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-action-card-behavior-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-android-app-retirement-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/android-app-retirement.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-android-app-retirement-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-checklists-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/checklists.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-checklists-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-creating-action-cards-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/creating-action-cards.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-creating-action-cards-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-lists-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/lists.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-lists-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-logbook-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/logbook.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-logbook-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-overview-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/overview.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-overview-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-schedule-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/schedule.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-schedule-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-search-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/getting-started/search.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-getting-started-search-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-apple-watch-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/actions-apple-watch.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-apple-watch-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-ios-widget-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/actions-ios-widget.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-ios-widget-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-share-extension-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/actions-share-extension.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-actions-share-extension-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-focusfilters-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/focusfilters.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-focusfilters-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-natural-language-inputs-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/natural-language-inputs.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-natural-language-inputs-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-siri-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/siri.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-siri-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-timepage-integration-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/actions/mastering-actions/timepage-integration.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-actions-mastering-actions-timepage-integration-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-collaboration-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/collaboration.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-collaboration-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-correction-tool-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/correction-tool.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-correction-tool-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-documents-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/documents.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-documents-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-eraser-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/eraser.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-eraser-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-images-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/images.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-images-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-lasso-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/lasso.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-lasso-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-layers-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/layers.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-layers-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-pencilcase-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/pencilcase.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-pencilcase-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-pensets-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/pensets.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-pensets-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-ruler-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/ruler.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-ruler-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-the-tools-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/the-tools.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-the-tools-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-tool-dock-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/tool-dock.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-tool-dock-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-tool-editor-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/creating-with-flow/tool-editor.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-creating-with-flow-tool-editor-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-2-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/introduction/flow-2.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-2-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-gallery-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/introduction/flow-gallery.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-gallery-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-overview-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/introduction/flow-overview.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-overview-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-web-viewer-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/introduction/flow-web-viewer.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-flow-web-viewer-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-receiving-support-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/introduction/receiving-support.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-introduction-receiving-support-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-collections-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/managing-your-documents/collections.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-collections-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-exporting-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/managing-your-documents/exporting.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-exporting-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-preferences-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/flow/managing-your-documents/preferences.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-flow-managing-your-documents-preferences-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-bonobo-bundle-membership-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/bonobo-bundle-membership.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-bonobo-bundle-membership-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-cancelling-a-subscription-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/cancelling-a-subscription.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-cancelling-a-subscription-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-creating-and-managing-accounts-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/creating-and-managing-accounts.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-creating-and-managing-accounts-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-email-communications-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/email-communications.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-email-communications-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-errors-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/errors.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-errors-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-moleskine-studio-name-change-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/moleskine-studio-name-change.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-moleskine-studio-name-change-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-purchasing-a-subscription-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/purchasing-a-subscription.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-purchasing-a-subscription-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-setting-up-syncing-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/setting-up-syncing.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-setting-up-syncing-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-membership-basics-siwa-issue-0125-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/membership/basics/siwa-issue-0125.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-membership-basics-siwa-issue-0125-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-customizing-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/overlap/introduction/customizing.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-customizing-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-getting-started-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/overlap/introduction/getting-started.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-getting-started-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-overlap-overview-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/overlap/introduction/overlap-overview.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-overlap-overview-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-shortcuts-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/overlap/introduction/shortcuts.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-overlap-introduction-shortcuts-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-actions-integration-actions-in-timepage-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/actions-integration/actions-in-timepage.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-actions-integration-actions-in-timepage-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-actions-integration-timepage-in-actions-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/actions-integration/timepage-in-actions.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-actions-integration-timepage-in-actions-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-adding-events-adding-additional-details-to-events-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/adding-events/adding-additional-details-to-events.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-adding-events-adding-additional-details-to-events-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-adding-events-how-to-add-an-event-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/adding-events/how-to-add-an-event.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-adding-events-how-to-add-an-event-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-apple-watch-how-to-use-timepage-for-apple-watch-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/apple-watch/how-to-use-timepage-for-apple-watch.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-apple-watch-how-to-use-timepage-for-apple-watch-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-setting-up-calendars-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/calendars/setting-up-calendars.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-setting-up-calendars-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-sharing-calendars-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/calendars/sharing-calendars.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-sharing-calendars-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-utilizing-multiple-calendars-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/calendars/utilizing-multiple-calendars.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-calendars-utilizing-multiple-calendars-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-gestures-navigation-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/gestures/navigation.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-gestures-navigation-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-gestures-on-events-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/gestures/on-events.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-gestures-on-events-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-interface-customization-day-shading-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/interface-customization/day-shading.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-interface-customization-day-shading-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-interface-customization-themes-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/interface-customization/themes.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-interface-customization-themes-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-about-timepage-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/introduction/about-timepage.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-about-timepage-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-frequently-asked-questions-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/introduction/frequently-asked-questions.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-frequently-asked-questions-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-getting-timepage-support-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/introduction/getting-timepage-support.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-getting-timepage-support-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-timepage-widgets-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/introduction/timepage-widgets.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-timepage-widgets-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-timepages-url-schemes-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/introduction/timepages-url-schemes.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-introduction-timepages-url-schemes-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-ios-12-rich-notifications-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/ios-12/rich-notifications.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-ios-12-rich-notifications-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-ios-12-shortcuts-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/ios-12/shortcuts.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-ios-12-shortcuts-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-day-view-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/navigation/navigating-day-view.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-day-view-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-heat-map-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/navigation/navigating-heat-map.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-heat-map-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-timeline-and-day-view-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/navigation/navigating-timeline-and-day-view.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-navigation-navigating-timeline-and-day-view-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-app-icon-badge-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/app-icon-badge.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-app-icon-badge-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-calendars-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/calendars.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-calendars-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-events-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/events.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-events-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-general-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/general.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-general-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-sounds-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/sounds.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-sounds-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-text-size-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/text-size.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-text-size-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-time-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/time.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-time-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-timeline-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/timeline.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-timeline-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-travel-and-directions-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/travel-and-directions.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-travel-and-directions-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-weather-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/weather.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-weather-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-weeks-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/preferences/weeks.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-preferences-weeks-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-search-accessing-search-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/search/accessing-search.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-search-accessing-search-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-smart-alerts-how-to-access-smart-alerts-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/smart-alerts/how-to-access-smart-alerts.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-smart-alerts-how-to-access-smart-alerts-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-smart-alerts-what-are-smart-alerts-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/smart-alerts/what-are-smart-alerts.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-smart-alerts-what-are-smart-alerts-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-actions-integration-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/timeline/actions-integration.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-actions-integration-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-changing-timeline-size-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/timeline/changing-timeline-size.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-changing-timeline-size-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-viewing-weather-information-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/timeline/viewing-weather-information.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-timeline-viewing-weather-information-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-ios-12-exchange-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/troubleshooting/ios12-exchange.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-ios-12-exchange-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-troubleshooting-calendars-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/troubleshooting/troubleshooting-calendars.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-troubleshooting-calendars-md" */),
  "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-troubleshooting-widgets-md": () => import("./../../../src/templates/docs.js?__contentFilePath=/opt/build/repo/src/support/timepage/troubleshooting/troubleshooting-widgets.md" /* webpackChunkName: "component---src-templates-docs-js-content-file-path-src-support-timepage-troubleshooting-troubleshooting-widgets-md" */),
  "component---src-templates-flowgallery-js": () => import("./../../../src/templates/flowgallery.js" /* webpackChunkName: "component---src-templates-flowgallery-js" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-animation-course-notes-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/animation_course_notes.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-animation-course-notes-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-bali-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/bali.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-bali-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-calendar-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/calendar.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-calendar-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-class-notes-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/class_notes.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-class-notes-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-neon-portrait-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/neon_portrait.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-neon-portrait-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-prague-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/prague.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-prague-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-ric-mayen-mountains-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/ric_mayen_mountains.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-ric-mayen-mountains-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-toucan-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/personal/toucan.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-personal-toucan-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-coffee-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/coffee.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-coffee-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-industrial-design-bottle-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/industrial_design_bottle.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-industrial-design-bottle-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-nga-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/nga.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-nga-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-outfit-design-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/outfit_design.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-outfit-design-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-park-project-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/park_project.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-park-project-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-sneaker-design-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/sneaker_design.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-sneaker-design-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-storyboard-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/storyboard.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-storyboard-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-survey-equipment-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/survey_equipment.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-survey-equipment-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-vehicle-design-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/professional/vehicle_design.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-professional-vehicle-design-md" */),
  "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-samples-bonobo-md": () => import("./../../../src/templates/flowgallerydetail.js?__contentFilePath=/opt/build/repo/src/flowgallery/flowgallery/samples/bonobo.md" /* webpackChunkName: "component---src-templates-flowgallerydetail-js-content-file-path-src-flowgallery-flowgallery-samples-bonobo-md" */),
  "component---src-templates-flowgallerytag-js": () => import("./../../../src/templates/flowgallerytag.js" /* webpackChunkName: "component---src-templates-flowgallerytag-js" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/de/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-de-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en-gb/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-gb-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/en/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-en-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/es/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-es-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/fr/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-fr-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/it/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-it-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ja/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ja-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ko/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ko-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/pt-br/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-pt-br-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/ru/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-ru-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/tr/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-tr-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hans/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hans-timepage-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/2.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/2.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/3.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/actions/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-actions-widgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-0-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/1.0.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-0-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-3-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/3.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-3-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-apple-design-award-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/apple-design-award.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-apple-design-award-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-ipad-app-of-the-year-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/ipad-app-of-the-year.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-ipad-app-of-the-year-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-ruler-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/flow/ruler.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-flow-ruler-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-3-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.3.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-3-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-41-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.41.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-41-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-42-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.42.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-42-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-43-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.43.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-43-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-44-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.44.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-44-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-45-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.45.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-45-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-46-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.46.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-46-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-47-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/1.47.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-1-47-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-10-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.10.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-10-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-11-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.11.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-11-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-8-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.8.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-8-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-8-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.8.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-8-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-9-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2.9.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2-9-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2019-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/2019.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-2019-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/3.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/3.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-6-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/3.6.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-6-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-7-5-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/3.7.5.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-3-7-5-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-1-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/4.1.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-1-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-2-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/4.2.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-2-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/4.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-ipad-1-4-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/ipad-1.4.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-ipad-1-4-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-ipad-1-7-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/ipad-1.7.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-ipad-1-7-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-morewidgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/morewidgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-morewidgets-md" */),
  "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-widgets-md": () => import("./../../../src/templates/whatsnew.js?__contentFilePath=/opt/build/repo/src/whatsnew/whatsnew/zh-hant/timepage/widgets.md" /* webpackChunkName: "component---src-templates-whatsnew-js-content-file-path-src-whatsnew-whatsnew-zh-hant-timepage-widgets-md" */)
}

